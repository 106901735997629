import { Styled } from './styled'
import { Fragment } from 'react'
import { useBreadcrumbsCtx } from '@providers/breadcrumbs'

export const Breadcrumbs = () => {
	const { list, disabled, onPush } = useBreadcrumbsCtx()

	if (disabled) return null

	return (
		<Styled.Wrap aria-label='Breadcrumb'>
			<Styled.Content itemScope itemType='https://schema.org/BreadcrumbList'>
				{list.map((breadcrubm, idx) => (
					<Fragment key={`${breadcrubm.asPath}-${idx}`}>
						{idx !== 0 && idx !== list.length && <Styled.Slash>/</Styled.Slash>}
						<li itemProp='itemListElement' itemScope itemType='https://schema.org/ListItem'>
							{idx === list.length - 1 ? (
								<Styled.NoninteractiveBreadcrumb itemProp='item' key={breadcrubm.asPath}>
									<span itemProp={breadcrubm.title}>{breadcrubm.title}</span>
								</Styled.NoninteractiveBreadcrumb>
							) : (
								<Styled.BreadcrumbLink
									itemProp='item'
									href={breadcrubm.asPath}
									onClick={() => onPush(idx)}
									key={breadcrubm.asPath}
								>
									<span itemProp={breadcrubm.title}>{breadcrubm.title}</span>
								</Styled.BreadcrumbLink>
							)}
							<meta itemProp="position" content={`${++idx}`} />
						</li>
					</Fragment>
				))}
			</Styled.Content>
		</Styled.Wrap>
	)
}
