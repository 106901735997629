export type JournalPoint = {
	content: string
	imageUrl: string
	imageUrlMobile?: string
	href: string
	imageClassName?: string
}

export const journalPointsDefalut: JournalPoint[] = [
	{
		content: 'Что такое квалифицированная электронная подпись',
		imageUrl: 'journal-item1.png',
		href: 'https://journal.credit.club/chto-takoe-kvalifitsirovannaya-elektronnaya-podpis',
	},
	{
		content: 'Как отказаться от страховки по кредиту в 2020 году',
		imageUrl: 'journal-item2.png',
		href: 'https://journal.credit.club/kak-otkazatsya-ot-strahovki-po-kreditu',
	},
	{
		content: 'Как взять кредит под залог недвижимости. Плюсы и минусы',
		imageUrl: 'journal-item3.png',
		href: 'https://journal.credit.club/kak-vzyat-dengi-pod-zalog-nedvizhimosti',
	},
]
