import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { PageSettings } from '@http/api-models/page-settings'

const Context = React.createContext<PageSettings | undefined>(undefined)

interface Props {
	pageSettings?: PageSettings
}

/**
 * Данный провайдер собирает в себе все серверные настройки страниц
 * которые были сформированы в объекте `pageSettings` на серверной стороне
 * приложения.
 *
 * На перспективу будет использоваться с CMS.
 * @param children
 * @param pageSettings
 * @constructor
 */
export const PageSettingsProvider = ({ children, pageSettings }: PropsWithChildren<Props>) => {
	const value = useMemo(() => pageSettings || {}, [pageSettings])
	return <Context.Provider value={value}>{children}</Context.Provider>
}

export const usePageSettings = () => React.useContext(Context) as PageSettings
