import Link from 'next/link';
import styled from 'styled-components';

export const Styled = {
	Wrap: styled.nav((({ theme }) => ({
		zIndex: 2,
		margin: '0 auto',
		maxWidth: 1160,
		width: '100%',
		padding: `0 ${theme.spacingsMobile.s}px`,
		marginBottom: theme.spacingsMobile.m,
		marginTop: theme.spacingsMobile.m,
		[theme.mediaQueries.mediaMinWidthDesktop]: {
			marginTop: 0,
			padding: 0,
		},
	}))),

	Content: styled.ol(({ theme }) => ({
		display: 'flex',
		gap: theme.spacingsMobile.xs,
	})),

	BreadcrumbLink: styled(Link)(({ theme }) => ({
		color: theme.colors.black30,
		maxWidth: 200,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',

		':hover': {
			color: theme.colors.mainblack,
			textDecoration: 'underline',
		}
	})),

	NoninteractiveBreadcrumb: styled.span(({ theme }) => ({
		maxWidth: 200,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: theme.colors.black30,
	})),

	Slash: styled.span(({ theme }) => ({
		color: theme.colors.black30,
	})),
}