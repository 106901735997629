import { useRouter, type NextRouter } from 'next/router'
import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
	type PropsWithChildren,
} from 'react'

interface Breadcrumb {
	title: string
	asPath: string
	route: string
}

type BreadcrumbsContextState = {
	list: Breadcrumb[]
	disabled: boolean
}

type BreadcrumbsContextValue = {
	list: Breadcrumb[]
	disabled: boolean
	onPush: (index: number) => void
}

const breadcrumbsCtx = createContext<BreadcrumbsContextValue>({
	list: [],
	disabled: false,
	onPush: null!,
})

const asd = () => {}

export const BreadcrumbsProvider = ({
	children,
	breadcrumb,
}: PropsWithChildren<{ breadcrumb?: { title?: string | null } }>) => {
	const router = useRouter() as NextRouter & { components: Record<string, any> }

	const title = breadcrumb?.title
	const root = router.query.city ? `/${router.query.city}` : '/'
	const path = router.asPath
	const route = router.route

	const [{ list, disabled }, setList] = useState<BreadcrumbsContextState>({
		list: [
			{
				title: 'Главная',
				asPath: root,
				route,
			},
			path !== root && breadcrumb?.title
				? {
						title: breadcrumb.title,
						asPath: path,
						route,
				  }
				: null,
		].filter(Boolean) as BreadcrumbsContextState['list'],
		disabled: root === path || !breadcrumb?.title,
	})

	useEffect(() => {
		setList((p) => {
			const [rootBreadcrumb, secondBreadcrumb, ...rest] = p.list
			const newList: Breadcrumb[] = [rootBreadcrumb]

			if (secondBreadcrumb?.route === route) return p

			if (secondBreadcrumb && route.startsWith(secondBreadcrumb.route) && breadcrumb?.title) {
				newList.push(secondBreadcrumb, ...rest, { title: breadcrumb.title, route, asPath: path })
			} else if (
				secondBreadcrumb &&
				!route.startsWith(secondBreadcrumb.route) &&
				breadcrumb?.title
			) {
				newList.push({ title: breadcrumb.title, route, asPath: path })
			} else if (breadcrumb?.title) {
				newList.push({ title: breadcrumb.title, route, asPath: path })
			}

			return {
				list: newList,
				disabled: root === path || !breadcrumb?.title,
			}
		})
	}, [path, title, root, route])

	return (
		<breadcrumbsCtx.Provider
			value={useMemo(
				() => ({
					onPush: (index: number) =>
						setList((p) => ({
							list: p.list.slice(0, index),
							disabled: p.disabled,
						})),
					disabled,
					list,
				}),
				[list, disabled]
			)}
		>
			{children}
		</breadcrumbsCtx.Provider>
	)
}

export const useBreadcrumbsCtx = () => useContext(breadcrumbsCtx)
