import React from 'react'

import { FeedbackProps } from './view'

export const feedbackData: NonNullable<FeedbackProps['data']> = [
	{
		name: 'Иван Петров',
		position: 'генеральный директор ООО «Скрин Рус»',
		avatarUrl: 'person1.png',
		text: (
			<>
				&laquo;Поразила фантастическая скорость принятия решения! Вышли на&nbsp;сделку быстро,
				не&nbsp;тянули. Ещё был персональный менеджер, к&nbsp;которому я&nbsp;мог обратиться
				в&nbsp;любое время. Ребята работают отлично&raquo;
			</>
		),
	},

	{
		name: 'Ирина Толмачёва',
		position: 'заём на ремонт',
		avatarUrl: 'person2.png',
		text: (
			<>
				&laquo;Серьезная организация, ответственно относятся к&nbsp;своей работе. Полное
				сопровождение всей сделки на&nbsp;каждом этапе. Здорово, что деньги перечислили
				быстро&nbsp;&mdash; я&nbsp;сразу&nbsp;же приступила к&nbsp;ремонту&raquo;
			</>
		),
	},

	{
		name: 'Кристина Черненко',
		position: 'основатель компании «Газтеплонефть»',
		avatarUrl: 'feedback-chernenko.png',
		shortText: (
			<>
				&laquo;Я&nbsp;с&nbsp;мужем основала компанию &laquo;Газтеплонефть&raquo;. Мы&nbsp;занимаемся
				грузоперевозками по&nbsp;России. Это непростой бизнес
			</>
		),
		text: (
			<>
				&laquo;Я&nbsp;с&nbsp;мужем основала компанию &laquo;Газтеплонефть&raquo;. Мы&nbsp;занимаемся
				грузоперевозками по&nbsp;России. Это непростой бизнес, но&nbsp;мы&nbsp;любим своё дело.
				<br />
				<br />
				Когда мы&nbsp;только начали, своих денег не&nbsp;хватало. Нам понадобились шесть миллионов
				на&nbsp;новые фуры, мы&nbsp;просто не&nbsp;справлялись с&nbsp;нашей загрузкой. Деньги нужны
				были срочно, поэтому как быть&nbsp;&mdash; не&nbsp;знали. В&nbsp;банках всё медленно, такие
				суммы никто не&nbsp;даст, да&nbsp;и&nbsp;никто не&nbsp;будет работать с&nbsp;молодыми
				компаниями. Поэтому обратились в&nbsp;Credit.Club.
				<br />
				<br />
				Позвонили&nbsp;&mdash; отреагировали моментально. За&nbsp;пару часов одобрили заём
				и&nbsp;в&nbsp;течение дня перевели первую часть денег на&nbsp;счёт. Никакой беготни, никаких
				переговоров&nbsp;&mdash; всё за&nbsp;один день. Я&nbsp;была удивлена. Продолжаем
				работать&quot;
			</>
		),
	},
	{
		name: 'Наталья Кимовна',
		position: 'генеральный директор ООО «МТ-Альянс»',
		avatarUrl: 'feedback-alians.png',
		shortText: (
			<>
				&laquo;Работала напрямую с&nbsp;директором Александром Фарленковым. Конечно, было
				некомфортно отдавать квартиру в&nbsp;залог. Удивительно, насколько сильно он&nbsp;погрузился
				в&nbsp;проблему
			</>
		),
		text: (
			<>
				&laquo;Работала напрямую с&nbsp;директором Александром Фарленковым. Конечно, было
				некомфортно отдавать квартиру в&nbsp;залог. Удивительно, насколько сильно он&nbsp;погрузился
				в&nbsp;проблему. Как он&nbsp;понимал, насколько тяжело вверить жилье и&nbsp;получить деньги.
				И&nbsp;как он&nbsp;вёл переговоры, как всё деликатно объяснял&nbsp;&mdash; каждый пункт
				договора, каждую деталь. В&nbsp;результате мы&nbsp;получили деньги, смогли выйти
				из&nbsp;кризиса и&nbsp;продолжить работу&raquo;
			</>
		),
	},
	{
		name: 'Армине Арутюнян',
		position: 'владелица магазина «Бизнес-шоп»',
		shortText: (
			<>
				&laquo;Меня удивила быстрая реакция этой компании! Очень всё пунктуально, а&nbsp;менеджеры
				вежливые. Очень удобный сервис!
			</>
		),
		text: (
			<>
				&laquo;Меня удивила быстрая реакция этой компании! Очень всё пунктуально, а&nbsp;менеджеры
				вежливые. Очень удобный сервис! Спасибо огромное Credit.Club, а&nbsp;отдельное&nbsp;&mdash;
				менеджеру Владимиру Холодилову! Всё очень нравится, надеюсь на&nbsp;сотрудничество
				и&nbsp;в&nbsp;будущем&raquo;
			</>
		),
	},
	{
		name: 'Иван Лукоянов',
		position: 'генеральный директор ООО «УралВег»',
		shortText: (
			<>
				&laquo;Необходим был заём для развития бизнеса, чтобы сначала выплачивать проценты,
				а&nbsp;потом всю сумму целиком. Рассмотрев
			</>
		),
		text: (
			<>
				&laquo;Необходим был заём для развития бизнеса, чтобы сначала выплачивать проценты,
				а&nbsp;потом всю сумму целиком. Рассмотрев несколько предложений от&nbsp;банков
				&laquo;за&nbsp;один день дадим деньги&raquo;, стало понятно, что у&nbsp;них не&nbsp;все так
				просто. То&nbsp;процент большой, то&nbsp;условия неподходящие.
				<br />
				<br />
				С&nbsp;Credit.Club уже общались&nbsp;&mdash; контакт был сохранен и&nbsp;очень даже кстати.
				Предложили хорошие условия, пошли навстречу по&nbsp;оформлению документов, на&nbsp;все
				вопросы отвечали открыто без всяких уловок. Будем пользоваться услугами данной компании еще
				не&nbsp;раз&quot;
			</>
		),
	},
	{
		name: 'Константин Лопанов',
		position: 'улучшение жилищных условий',
		text: (
			<>
				&laquo;Очень клиентоориентированная компания, все максимально честно, быстро
				и&nbsp;прозрачно&raquo;
			</>
		),
	},
]
